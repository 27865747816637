import axios from "axios";
import toast from 'react-hot-toast'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

});

instance.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem("accessToken");
    let accessToken = JSON.parse(token);
    config.headers.authorization = accessToken;

    return config;
  },
  (error) => {
    return error;
  }
);

instance.interceptors.response.use(
  (response) => {
    const { data } = response;
    // if (data.notify) {
    //   const customId = data.message ?? "success";
    //   toast.success(data.message ?? "success", { toastId: customId });
    // }
    return response;
  },
  (error) => {
    const { response } = error;

    // if (response.status === 401)
    //   toast.error(`(401) ${response?.data?.message}`);
    // else toast.error(response?.data?.message);

    if (response.status === 401) {
      toast.error(`${response?.data?.message}`);
      localStorage.clear();
      setTimeout(() => {
        window.location.replace(`${window.location.origin}/`);
      }, 1500);
    }
    return error;
  }
);

export default instance;
