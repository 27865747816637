import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import CircularProgress from "@mui/material/CircularProgress";
import "./Stripe.css";

// stripe imports
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { toast } from "react-hot-toast";
import axios from "../../api/axios";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

function PaymentPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [account,setAccount] = useState({});

  React.useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);

  useEffect(() => {
    if (location?.state) {
        let user = localStorage.getItem('user');
        if(user) {
            let parse = JSON.parse(user);
            setAccount(parse);
        }
        setData(location.state);
    }
  }, [location]);


//  const data = {
//     token: token,
//     account: account,
//     other: {
//        userId: account._id,
//        name: account.name,
//        email: account.email,
//        vatNumber: account.vatNumber,
//        receipt: '',
//        credits: credits,
//        netAmount: total,
//        date: getCustomDate(),
//        method: '',
//        fee: handleFee,
//        vatCharge: (tax * price) / 100,
//        tax: tax,
//     },
//  }

  // Stripe Integration
  const [clientSecretKey, setClientSecretKey] = useState("");

  const getClientSecret = async () => {
    let price   = data.total;
    let name    = data.customer;
    let credits = data.credits;
      
    try {
       await axios
          .post(`getStripeSecret`, { price, name, credits })
          .then((result) => {
             if (result.status == 200) {
                const clientSecret = result?.data?.clientSecret;
                let stripeData = {
                    credits: data.credits,
                    netAmount: data.total,
                    fee: data.handleFee,
                    vatCharge: (data.tax * data.price) / 100,
                    tax: data.tax,
                }
                let s_data = JSON.stringify(stripeData)
                localStorage.setItem('s_Data',s_data);
                if (clientSecret) setClientSecretKey(clientSecret);
             } else {
                toast.error('Something went wrong')
             }
          })
    } catch (error) {
       if (process.env.REACT_APP_MODE) console.log(error)
    }
 }

//   const buyUserPlan = (planObject) => {
//     if (Object.keys(planObject).length > 0) {
//       const user = localStorage.getItem("user");
//       const parsedUser = JSON.parse(user);

//       if (parsedUser && parsedUser.role === "student") {
//         const object = {
//           user_id: parsedUser._id,
//           minutes_in_day: Number(planObject.planMinutesPerDay),
//           days_in_week: Number(planObject.planDaysPerWeek),
//           plan_duration: Number(planObject.month),
//           plan_price: Math.round(Number(total)),
//         };
//         StudentAPI.buyPlan(object)
//           .then((response) => {
//             if (response?.response?.data?.code == 403) {
//               let state = {
//                 openMail: true,
//               };

//               navigate("/v1/student/setting/account-setting", { state });
//             } else {
//               const clientSecret = response?.data?.data?.clientSecret;
//               if (clientSecret) setClientSecretKey(clientSecret);

//               if (response?.code === "ERR_BAD_RESPONSE") {
//                 setTimeout(() => setRedirectMessage(true), 2000);
//                 setTimeout(() => {
//                   window.location.replace(
//                     `${window.location.origin}${redirectPath}`
//                   );
//                 }, 5000);
//               }
//             }
//           })
//           .catch((error) => {
//             console.log(error);
//           });
//       }
//     }
//   };

  React.useEffect(() => {
    if(Object.keys(data).length > 0 && Object.keys(account).length > 0) {
        getClientSecret();
    }
  }, [data]);

  const appearance = {
    theme: "night",
  };
  const options = {
    clientSecret: clientSecretKey,
    appearance: { theme: "stripe" },
  };

  return (
    <>
      <Box
        sx={{
          width:  "94%",
          minHeight: "80vh",
          height: "max-content",
          borderRadius: "12px",
          backgroundColor: "#eaeaea",
          margin: "0 auto",
          marginTop: "5rem",
          paddingBottom:'2rem',
        }}
      >
        <Box sx={{ width: "100%", height: "90%" }}>
          <Grid
            container
            spacing={1}
            sx={{
              width: "100%",
              margin: "0 auto",
              height: "100%",
              marginTop: "3rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              lg={6}
              xs={11}
              sx={{
                display: "flex",
                height: "max-content",
                justifyContent: "center",
                alignItems: "center",
                flexFlow: "column",
              }}
            >
              <Box
                component="span"
                sx={{ fontSize: "24px", margin: "1rem 0", fontWeight: "600" }}
              >
                Payment Information
              </Box>
              <Box sx={{borderRadius:'8px',backgroundColor:'white',minWidth:'200px',minHeight:'100px',display:'flex',justifyContent:'center',alignItems:'center'}} >
                {/* Payment Card Box */}
                {clientSecretKey && (
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm data={data} />
                  </Elements>
                )}
                {!clientSecretKey && <CircularProgress />}
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              xs={11}
              sx={{
                display: "flex",
                height: "max-content",
                justifyContent: "center",
                alignItems: "center",
                flexFlow: "column",
              }}
            >
              <Box
                component="span"
                sx={{ fontSize: "24px", margin: "1rem 0", fontWeight: "600" }}
              >
                Payment Summary
              </Box>
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "5rem",
                }}
              >
                <Box
                  component="span"
                  sx={{ fontSize: "18px", fontWeight: "400" }}
                >
                  {data?.name} 
                </Box>
              </Box>
              <Box
                sx={{
                  width: { md: "80%", xs: "94%" },
                  height: "62px",
                  border: "1px solid rgba(147, 147, 147, 1)",
                  borderRadius: "6px",
                  backgroundColor: "white",
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  },
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    fontSize: { sm: "18px", xs: "16px" },
                    fontWeight: "600",
                    marginLeft: "1rem",
                  }}
                >
                  Total due amount: {`${data?.total} ${data?.type}`}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default PaymentPage;
